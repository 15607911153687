import { ChangeEvent, Dispatch, SetStateAction, useState, useEffect } from 'react';
import {
  Box,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
  Tabs,
  Tab,
  Button,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import classNames from 'classnames';

import {
  AdFieldBathsEnum,
  AdFieldBedsEnum,
  CompletionStatusEnum,
  AdsFilteringType,
  RentPeriodEnum,
  TransactionTypeEnum,
  PropertyKindEnum,
  AdFieldOccupancyEnum,
  AdFieldFurnitureEnum,
  AdFieldRenovationEnum,
  AdFieldFloorEnum,
  UserModel,
  UserKindEnum,
  UserTariffPlanEnum,
  HeaderDialogsEnum,
} from '@/types';
import {
  predefinedVars,
  getLabelsAndTexts,
  getMoreFiltersControlsVisibility,
} from './AdsFilters.init';
import { MyButton, FormSelectCustom } from '@/components';

import { SelectItemButton } from './SelectItemButton';
import styles from './AdsFilters.module.scss';

import { ReactComponent as CaretDown } from './caretDown.svg';
import { LocationModel } from '@/types/location';
import { LocationAutocomplete } from '@/components/LocationAutocomplete/LocationAutocomplete';
import { MoreFiltersDialog } from './MoreFiltersDialog';

import { MoreFiltersControl } from './MoreFiltersControl';
import { MoreFilters } from './MoreFilters';
import { DumbFormSelect } from '@/components/uiComponents/FormSelect';
import { handoverChoices, rentSalesExtraChoices } from './MoreFilters.init';
import { ReactComponent as HotIcon } from './hot.svg';
import { NumericFormat } from 'react-number-format';
import { useDialogs } from '@/components/Dashboard/DialogsContext';
import { TrialBanner, DiscountBanner } from './Banners';

interface FilterState {
  locationId: string[];
  propertyKind: PropertyKindEnum[];
  transactionType: TransactionTypeEnum[];
  rentFrequency: RentPeriodEnum | undefined;
  completionStatus: CompletionStatusEnum[];
  beds: AdFieldBedsEnum[];
  baths: AdFieldBathsEnum[];
  furniture: AdFieldFurnitureEnum[];
  renovation: AdFieldRenovationEnum[];
  floor: AdFieldFloorEnum[];
  occupancy: AdFieldOccupancyEnum[];
  postHandover: boolean | undefined;
  handover: HandoverKeys;
  price: PriceKeys;
  size: SizeKeys;
  downPayment: number | undefined;
  keywords: string | undefined;
  creator: CreatorType;
  isMyAgency: boolean | undefined;
  myFriends: boolean | undefined;
}

interface BasicFilterState {
  locationId: string[];
  propertyKind: PropertyKindEnum[];
  transactionType: TransactionTypeEnum[];
  rentFrequency: RentPeriodEnum | undefined;
  beds: AdFieldBedsEnum[];
  price: PriceKeys;
}
interface HandoverKeys {
  quarter: string | undefined;
  year: string | undefined;
}
interface PriceKeys {
  priceMin: string | undefined;
  priceMax: string | undefined;
}
interface SizeKeys {
  areaMin: string | undefined;
  areaMax: string | undefined;
}
interface CreatorType {
  id: string | undefined;
  name: string | undefined;
  kind: string | undefined;
}

const initialFilterState: FilterState = {
  locationId: [],
  propertyKind: [],
  transactionType: [],
  rentFrequency: undefined,
  completionStatus: [],
  beds: [],
  baths: [],
  furniture: [],
  renovation: [],
  floor: [],
  occupancy: [],
  postHandover: undefined,
  handover: {
    quarter: undefined,
    year: undefined,
  },
  price: {
    priceMin: undefined,
    priceMax: undefined,
  },
  size: {
    areaMin: undefined,
    areaMax: undefined,
  },
  downPayment: undefined,
  keywords: undefined,
  creator: {
    id: undefined,
    name: undefined,
    kind: undefined,
  },
  isMyAgency: undefined,
  myFriends: undefined,
};

const initialBasicFilterState: BasicFilterState = {
  locationId: [],
  propertyKind: [],
  transactionType: [],
  rentFrequency: undefined,
  beds: [],
  price: {
    priceMin: undefined,
    priceMax: undefined,
  },
};

export const AdsFilters = ({
  filteringObj,
  onChangeFilters,
  locations,
  inputAddressValue,
  setInputAddressValue,
  setSelectedLocation,
  selectedLocation,
  setChangeFilter,
  isRent,
  user,
  userSettings,
}: {
  filteringObj: AdsFilteringType;
  onChangeFilters: (value: AdsFilteringType, shouldSendRequest?: boolean) => void;
  locations: LocationModel[];
  setLocations: Dispatch<SetStateAction<LocationModel[]>>;
  isMyAds?: boolean;
  selectedLocation: LocationModel | null;
  setSelectedLocation: (value: LocationModel | null) => void;
  inputAddressValue: string;
  setInputAddressValue: (value: string) => void;
  setChangeFilter: (value: boolean) => void;
  isRent?: boolean;
  user: UserModel;
  userSettings: any;
}) => {
  const {
    activityKind,
    propertyKind,
    areaMin = 0,
    areaMax = 0,
    beds,
    baths,
    priceMin = 0,
    priceMax = 0,
    rentFrequency,
    transactionType,
    completionStatus,
  } = filteringObj;
  const isTariffFree = user.tariffPlan?.name === UserTariffPlanEnum.Free;
  const isTariffTrial = user.tariffPlan?.name === UserTariffPlanEnum.Trial;
  const hasSubscriptions = user.hasSubscriptions || false;
  const { bedsBathsText, priceLabel } = getLabelsAndTexts(filteringObj, userSettings);
  const [propertyTypeTab, setPropertyTypeTab] = useState<number>(0);
  const { openDialog, setOpenDialog } = useDialogs();
  const [openFilterDialog, setOpenFilterDialog] = useState(false);
  // const [isMyAgency, setIsMyAgency] = useState<boolean>(false)
  const [counterFilters, setCounterFilters] = useState<number>(0);
  const [filterState, setFilterState] = useState<FilterState>(initialFilterState);
  const [basicFilterState, setBasicFilterState] =
    useState<BasicFilterState>(initialBasicFilterState);
  const updateFilterState = (key: keyof FilterState, value: any) => {
    setFilterState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  const updateBasicFilterState = (key: keyof BasicFilterState, value: any) => {
    setBasicFilterState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const updateNestedState = <T extends keyof FilterState>(
    nestedKey: T,
    key: keyof FilterState[T],
    value: any
  ) => {
    setFilterState((prevState) => ({
      ...prevState,
      [nestedKey]: {
        ...(prevState[nestedKey] as any),
        [key]: value,
      },
    }));
  };

  const updateBasicNestedState = <T extends keyof BasicFilterState>(
    nestedKey: T,
    key: keyof BasicFilterState[T],
    value: any
  ) => {
    setBasicFilterState((prevState) => ({
      ...prevState,
      [nestedKey]: {
        ...(prevState[nestedKey] as any),
        [key]: value,
      },
    }));
  };

  const countNonEmptyFields = (filterState: FilterState): number => {
    let count = 0;
    Object.entries(filterState).forEach(([key, value]) => {
      if (key === 'isMyAgency' || key === 'myFriends') {
        return;
      }
      if (Array.isArray(value)) {
        if (value.length > 0) count++;
      } else if (typeof value === 'object' && value !== null) {
        if (
          Object.values(value).some(
            (subValue) => subValue !== undefined && subValue !== null && subValue !== ''
          )
        ) {
          count++;
        }
      } else if (value !== undefined && value !== null && value !== '') {
        count++;
      }
    });
    return count;
  };

  useEffect(() => {
    const newFloor = filteringObj?.floor as AdFieldFloorEnum[];
    const floor = newFloor?.map((el: AdFieldFloorEnum) => el as AdFieldFloorEnum);
    setFilterState((prevState) => ({
      ...prevState,
      locationId: (filteringObj.locationId as string[]) || [],
      transactionType: (filteringObj.transactionType as TransactionTypeEnum[]) || [],
      rentFrequency: filteringObj.rentFrequency,
      completionStatus: (filteringObj.completionStatus as CompletionStatusEnum[]) || [],
      propertyKind: filteringObj.propertyKind || [],
      beds: (filteringObj.beds as AdFieldBedsEnum[]) || [],
      downPayment: filteringObj.downPayment,
      baths: (filteringObj.baths as AdFieldBathsEnum[]) || [],
      furniture: (filteringObj.furniture as AdFieldFurnitureEnum[]) || [],
      renovation: (filteringObj.renovation as AdFieldRenovationEnum[]) || [],
      postHandover: filteringObj.postHandover || undefined,
      floor: (floor as AdFieldFloorEnum[]) || [],
      occupancy: (filteringObj.occupancy as AdFieldOccupancyEnum[]) || [],
      keywords: filteringObj.keywords,
      isMyAgency: filteringObj.isMyAgency || false,
      myFriends: filteringObj.myFriends || false,
    }));
    updateNestedState('size', 'areaMin', filteringObj.areaMin);
    updateNestedState('size', 'areaMax', filteringObj.areaMax);
    updateNestedState('price', 'priceMin', filteringObj.priceMin);
    updateNestedState('price', 'priceMax', filteringObj.priceMax);
    updateNestedState('handover', 'quarter', filteringObj?.handover?.quarter);
    updateNestedState('handover', 'year', filteringObj?.handover?.year);
    updateNestedState('creator', 'id', filteringObj?.creator?.id);
    updateNestedState('creator', 'name', filteringObj?.creator?.name);
    updateNestedState('creator', 'kind', filteringObj?.creator?.kind);
    setBasicFilterState((prevState) => ({
      ...prevState,
      locationId: (filteringObj.locationId as string[]) || [],
      transactionType: (filteringObj.transactionType as TransactionTypeEnum[]) || [],
      rentFrequency: filteringObj.rentFrequency,
      propertyKind: filteringObj.propertyKind || [],
      beds: (filteringObj.beds as AdFieldBedsEnum[]) || [],
    }));
    updateBasicNestedState('price', 'priceMin', filteringObj.priceMin);
    updateBasicNestedState('price', 'priceMax', filteringObj.priceMax);
  }, [filteringObj]);

  useEffect(() => {
    setCounterFilters(countNonEmptyFields(filterState));
  }, [filterState]);

  const handleInputAddressClear = (isQueryParams?: boolean) => {
    setInputAddressValue('');
    setSelectedLocation(null);
    updateFilterState('locationId', undefined);
    if (isQueryParams) {
      updateBasicFilterState('locationId', undefined);
      onChangeFilters({ ...filteringObj, address: [], locationId: undefined }, true);
    }
  };

  const handleResetFilters = () => {
    setInputAddressValue('');
    setSelectedLocation(null);
    onChangeFilters({ ...predefinedVars.emptyFilteringObj }, true);
    setChangeFilter(false);
  };

  const handleSearchSubmit = () => {
    onChangeFilters(
      {
        ...filteringObj,
        address: [
          {
            addressEn: inputAddressValue || '',
          },
        ],
        locationId: selectedLocation?.id ?? locations?.[0]?.id ?? undefined,
      },
      true
    );
  };

  const propertyKindValueItem = predefinedVars.propertyKindValues.find(
    (item) => item.value === filterState?.propertyKind?.[0]
  );
  const propertyKindValueLabel = propertyKindValueItem?.label || 'Property type';

  const myAgency = {
    id: user.kind !== UserKindEnum.Agency ? user?.employer?.id : user.id,
    name: user.kind !== UserKindEnum.Agency ? user?.employer?.name : user.name,
    kind: UserKindEnum.Agency,
  };

  const onSelectLocation = (value: LocationModel, isQueryParams?: boolean) => {
    setSelectedLocation(value);
    updateFilterState('locationId', [value.id]);
    if (isQueryParams) {
      updateBasicFilterState('locationId', [value.id]);
      onChangeFilters(
        {
          ...filteringObj,
          address: [
            {
              addressEn: inputAddressValue || '',
            },
          ],
          locationId: value.id,
        },
        true
      );
    }
  };

  const handleChangeTransactionType = (
    value: TransactionTypeEnum,
    name?: string,
    isQueryParams?: boolean
  ) => {
    let newSelection: TransactionTypeEnum[] = [];
    if (filterState?.transactionType?.includes(value)) {
      newSelection = filterState?.transactionType?.filter((item) => item !== value);
    } else {
      newSelection = [...filterState?.transactionType, value];
    }
    updateFilterState('transactionType', newSelection);
    if (isQueryParams) {
      updateBasicFilterState('transactionType', newSelection);
      onChangeFilters({ ...filteringObj, transactionType: newSelection }, true);
    }
  };

  const handleChangeRentFrequency = (
    value: RentPeriodEnum,
    name?: string,
    isQueryParams?: boolean
  ) => {
    const newSelection =
      filterState?.rentFrequency === value ? undefined : (value as RentPeriodEnum);
    updateFilterState('rentFrequency', newSelection);
    if (isQueryParams) {
      updateBasicFilterState('rentFrequency', newSelection);
      onChangeFilters({ ...filteringObj, rentFrequency: newSelection }, true);
    }
  };

  const handleChangeCompletionStatus = (
    value: CompletionStatusEnum,
    name?: string,
    isQueryParams?: boolean
  ) => {
    let newSelection: CompletionStatusEnum[] = [];
    if (filterState?.completionStatus?.includes(value)) {
      newSelection = filterState?.completionStatus?.filter((item) => item !== value);
    } else {
      newSelection = [...filterState?.completionStatus, value];
    }
    updateFilterState('completionStatus', newSelection);
  };

  const handleChangePropertyKind = (
    value: PropertyKindEnum,
    name?: string,
    isQueryParams?: boolean
  ) => {
    let newSelection: PropertyKindEnum[] = [];
    if (filterState?.propertyKind?.includes(value)) {
      newSelection = [];
    } else {
      newSelection = [value];
    }
    updateFilterState('propertyKind', newSelection);
    if (isQueryParams) {
      updateBasicFilterState('propertyKind', newSelection);
      onChangeFilters({ ...filteringObj, propertyKind: newSelection }, true);
    }
  };

  const handleChangeSize = (e: ChangeEvent<HTMLInputElement>, name?: string) => {
    const value = e.target.value.replace(/\s/g, '');
    if (name) {
      const keyOf = name as keyof SizeKeys;
      updateNestedState('size', keyOf, value);
    }
  };

  const handleChangeBeds = (value: AdFieldBedsEnum, name?: string, isQueryParams?: boolean) => {
    let newSelection: AdFieldBedsEnum[] = [];
    if (filterState?.beds?.includes(value)) {
      newSelection = filterState?.beds.filter((item) => item.toString() !== value.toString());
    } else {
      newSelection = [...filterState?.beds, value];
    }
    updateFilterState('beds', newSelection);
    if (isQueryParams) {
      updateBasicFilterState('beds', newSelection);
      onChangeFilters({ ...filteringObj, beds: newSelection }, true);
    }
  };

  const handleChangePrice = (
    e: ChangeEvent<HTMLInputElement>,
    name?: string,
    isQueryParams?: boolean
  ) => {
    const value = e.target.value.replace(/\s/g, '');
    if (name) {
      const keyOf = name as keyof PriceKeys;
      updateNestedState('price', keyOf, value);
      isQueryParams && updateBasicNestedState('price', keyOf, value);
    }
    if (name === 'priceMin') {
      isQueryParams && onChangeFilters({ ...filteringObj, priceMin: value });
    } else {
      isQueryParams && onChangeFilters({ ...filteringObj, priceMax: value });
    }
  };

  const handleChangeDownPayment = (value: number) => {
    updateFilterState('downPayment', value);
  };

  const handleChangeBaths = (value: AdFieldBathsEnum, name?: string, isQueryParams?: boolean) => {
    let newSelection: AdFieldBathsEnum[] = [];
    if (filterState?.baths?.includes(value)) {
      newSelection = filterState?.baths.filter((item) => item !== value);
    } else {
      newSelection = [...filterState?.baths, value];
    }
    updateFilterState('baths', newSelection);
  };

  const handleChangeFurnished = (
    value: AdFieldFurnitureEnum | string,
    name?: string,
    isQueryParams?: boolean
  ) => {
    let newSelection: AdFieldFurnitureEnum[] = [];
    if (value === 'any') {
      return updateFilterState('furniture', [
        AdFieldFurnitureEnum.Furnished,
        AdFieldFurnitureEnum.PartlyFurnished,
        AdFieldFurnitureEnum.Unfurnished,
      ]);
    } else {
      if (filterState?.furniture?.includes(value as AdFieldFurnitureEnum)) {
        newSelection = filterState?.furniture?.filter((item) => item !== value);
      } else {
        newSelection = [...filterState?.furniture, value as AdFieldFurnitureEnum];
      }
      updateFilterState('furniture', newSelection);
    }
  };

  const handleChangeRenovation = (
    value: AdFieldRenovationEnum | string,
    name?: string,
    isQueryParams?: boolean
  ) => {
    let newSelection: AdFieldRenovationEnum[] = [];
    if (value === 'any') {
      return updateFilterState('renovation', [
        AdFieldRenovationEnum.Renovation,
        AdFieldRenovationEnum.NonRenovation,
      ]);
    } else {
      if (filterState?.renovation?.includes(value as AdFieldRenovationEnum)) {
        newSelection = filterState?.renovation?.filter((item) => item !== value);
      } else {
        newSelection = [...filterState?.renovation, value as AdFieldRenovationEnum];
      }
      updateFilterState('renovation', newSelection);
    }
  };

  const handleChangePostHandover = (value: boolean, name?: string, isQueryParams?: boolean) => {
    const newSelection = filterState?.postHandover === value ? undefined : value;
    updateFilterState('postHandover', newSelection);
  };

  const handleChangeHandover = (value: string, name?: string, isQueryParams?: boolean) => {
    if (name) {
      const keyOf = name as keyof HandoverKeys;
      const handoverFilterState = filterState?.handover?.[keyOf];
      const newSelection = handoverFilterState === value ? undefined : value;
      if (name === 'year' || (name === 'quarter' && filterState.handover.year)) {
        updateNestedState('handover', keyOf, newSelection);
      }
    }
  };

  const handleChangeFloor = (
    value: AdFieldFloorEnum | string,
    name?: string,
    isQueryParams?: boolean
  ) => {
    let newSelection: AdFieldFloorEnum[] = [];
    if (value === 'any') {
      return updateFilterState('floor', [
        AdFieldFloorEnum.Low,
        AdFieldFloorEnum.Middle,
        AdFieldFloorEnum.High,
      ]);
    } else {
      if (filterState?.floor?.includes(value as AdFieldFloorEnum)) {
        newSelection = filterState?.floor?.filter((item) => item !== value);
      } else {
        newSelection = [...filterState?.floor, value as AdFieldFloorEnum];
      }
      updateFilterState('floor', newSelection);
    }
  };

  const handleChangeOccupancy = (
    value: AdFieldOccupancyEnum,
    name?: string,
    isQueryParams?: boolean
  ) => {
    let newSelection: AdFieldOccupancyEnum[] = [];
    if (filterState?.occupancy?.includes(value)) {
      newSelection = filterState?.occupancy?.filter((item) => item !== value);
    } else {
      newSelection = [...filterState?.occupancy, value];
    }
    updateFilterState('occupancy', newSelection);
  };

  const handleChangeCreator = (value: CreatorType, name?: string, isQueryParams?: boolean) => {
    updateNestedState('creator', 'id', value?.id);
    updateNestedState('creator', 'name', value?.name);
    updateNestedState('creator', 'kind', value?.kind);
  };

  const handleChangeMyAgency = (value: boolean) => {
    updateFilterState('isMyAgency', value);
  };

  const handleChangeMyFriend = (value: boolean) => {
    updateFilterState('myFriends', value);
  };

  const handleChangeKeywords = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    updateFilterState('keywords', value);
  };

  const handleCloseDialog = () => {
    setOpenFilterDialog(false);
    handleResetFilters();
    onChangeFilters(
      {
        locationId: basicFilterState.locationId,
        transactionType: basicFilterState.transactionType,
        rentFrequency: basicFilterState.rentFrequency,
        propertyKind: basicFilterState.propertyKind,
        beds: basicFilterState.beds,
        priceMin: Number(basicFilterState.price.priceMin) || undefined,
        priceMax: Number(basicFilterState.price.priceMax) || undefined,
      },
      true
    );
  };

  const handleShowResult = () => {
    const isMyAgency = filterState.isMyAgency;
    const isDistress = filterState.transactionType.includes(TransactionTypeEnum.Distress);
    const isResale = filterState?.transactionType?.includes(TransactionTypeEnum.Resale);
    const isReady = filterState?.transactionType?.includes(TransactionTypeEnum.Ready);
    const isOffPlan =
      isDistress && filterState.completionStatus.includes(CompletionStatusEnum.Ready);
    const handoverVisible = isDistress || isResale;
    const floorVisible =
      filterState?.propertyKind.length === 0 ||
      [
        PropertyKindEnum.Apartment,
        PropertyKindEnum.HotelApartment,
        PropertyKindEnum.Floor,
        PropertyKindEnum.Office,
        PropertyKindEnum.Warehouse,
      ].includes(filterState?.propertyKind?.[0]);
    const renovationValid = isRent || isReady || (isDistress && isOffPlan);
    const renovationVisible =
      renovationValid &&
      (filterState?.propertyKind.length === 0 ||
        ![
          PropertyKindEnum.HotelApartment,
          PropertyKindEnum.Warehouse,
          PropertyKindEnum.ResidentialLand,
          PropertyKindEnum.IndustrialLand,
          PropertyKindEnum.CommercialLand,
          PropertyKindEnum.Shop,
        ].includes(filterState?.propertyKind?.[0]));
    const availabilityValid = isReady || (isDistress && isOffPlan);
    const availabilityVisible =
      availabilityValid &&
      (filterState?.propertyKind.length === 0 ||
        ![
          PropertyKindEnum.ResidentialLand,
          PropertyKindEnum.IndustrialLand,
          PropertyKindEnum.CommercialLand,
        ].includes(filterState?.propertyKind?.[0]));

    onChangeFilters(
      {
        ...filteringObj,
        locationId: filterState.locationId,
        transactionType: filterState.transactionType,
        rentFrequency: filterState.rentFrequency,
        completionStatus: isDistress ? filterState.completionStatus : undefined,
        propertyKind: filterState.propertyKind,
        areaMin: Number(filterState?.size?.areaMin) || undefined,
        areaMax: Number(filterState.size.areaMax) || undefined,
        beds: filterState.beds,
        priceMin: Number(filterState.price.priceMin) || undefined,
        priceMax: Number(filterState.price.priceMax) || undefined,
        downPayment: handoverVisible ? filterState.downPayment : undefined,
        baths: filterState.baths,
        furniture: filterState.furniture,
        renovation: renovationVisible ? filterState.renovation : undefined,
        postHandover: handoverVisible ? filterState.postHandover : undefined,
        'handover.quarter':
          handoverVisible && filterState.handover.quarter && filterState.handover.year
            ? filterState.handover.quarter
            : handoverVisible && filterState.handover.year
              ? 'Q4'
              : undefined,
        'handover.year':
          filterState.handover.year && handoverVisible ? filterState.handover.year : undefined,
        floor: floorVisible ? filterState.floor : undefined,
        occupancy: availabilityVisible ? filterState.occupancy : undefined,
        'creator.id': isMyAgency ? myAgency.id : filterState.creator.id || undefined,
        'creator.name': isMyAgency ? myAgency.name : filterState.creator.name || undefined,
        'creator.kind': isMyAgency ? myAgency.kind : filterState.creator.kind || undefined,
        keywords: filterState.keywords,
        creator: !filterState.creator.id
          ? undefined
          : {
              id: filterState.creator.id,
              name: filterState.creator.name,
              kind: filterState.creator.kind,
            },
        handover:
          !filterState.handover.year || !handoverVisible
            ? {
                quarter: undefined,
                year: undefined,
              }
            : {
                quarter: filterState.handover.quarter,
                year: filterState.handover.year,
              },
        isMyAgency: filterState.isMyAgency || undefined,
        myFriends: filterState.myFriends || undefined,
      },
      true
    );
  };

  const handleClickOpenDialog = (dialogType: HeaderDialogsEnum) => {
    setOpenDialog((prevState) => ({
      ...prevState,
      [dialogType]: !prevState[dialogType],
    }));
  };

  const rentSalesData = isRent
    ? predefinedVars.rentFrequencyValues
    : predefinedVars.transactionTypeValues;

  const isQueryParams = true;

  const buttonName = counterFilters > 0 ? `Filters (${counterFilters})` : 'More filters';

  return (
    <>
      <div className={styles.filtersContainer}>
        <Grid container alignItems='center' justifyContent='space-between' spacing={1}>
          <Grid item xs={12} sm={isRent ? 5 : 4} lg={isRent ? 5 : 4} md={isRent ? 5 : 4}>
            <div className={styles.frequencyContent}>
              {rentSalesData.map(({ label, value }) => {
                return isRent ? (
                  <Button
                    key={value}
                    className={classNames(styles.itemButton, {
                      [styles.active]: filterState?.rentFrequency?.toString() === value.toString(),
                    })}
                    onClick={() =>
                      handleChangeRentFrequency(
                        value as RentPeriodEnum,
                        'rentFrequency',
                        isQueryParams
                      )
                    }
                    variant='contained'
                  >
                    {label}
                  </Button>
                ) : (
                  <Button
                    key={value}
                    className={classNames(styles.itemButton, {
                      [styles.active]: filterState?.transactionType.includes(
                        value as TransactionTypeEnum
                      ),
                    })}
                    endIcon={value === TransactionTypeEnum.Distress ? <HotIcon /> : null}
                    onClick={() =>
                      handleChangeTransactionType(
                        value as TransactionTypeEnum,
                        'transactionType',
                        isQueryParams
                      )
                    }
                    variant='contained'
                  >
                    {label}
                  </Button>
                );
              })}
            </div>
          </Grid>
          <Grid
            item
            xs={9.6}
            sm={isRent ? 4.6 : 5.6}
            lg={isRent ? 4.6 : 5.6}
            md={isRent ? 4.6 : 5.6}
          >
            <LocationAutocomplete
              setInputAddressValue={setInputAddressValue}
              locations={locations}
              inputAddressValue={inputAddressValue}
              handleClear={() => handleInputAddressClear(isQueryParams)}
              onSelect={(value: LocationModel) => onSelectLocation(value, isQueryParams)}
            />
          </Grid>
          <Grid item xs={2.4} sm={2.4} lg={2.4} md={2.4}>
            <Box
              sx={{
                width: {
                  sm: '166px',
                },
              }}
            >
              <MyButton
                data={{
                  buttonName: 'Search',
                  styleType: 'button',
                  variant: 'contained',
                }}
                onClick={handleSearchSubmit}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid container alignItems='center' justifyContent='space-between' spacing={1}>
          <Grid item xs={12} sm={isRent ? 5 : 4} lg={isRent ? 4.9 : 4} md={isRent ? 4.9 : 4}>
            <FormSelectCustom label='' value={propertyKindValueLabel} textCapitalize={false}>
              <div className={styles.floor}>
                <Tabs
                  value={propertyTypeTab}
                  onChange={(_, val) => setPropertyTypeTab(val)}
                  aria-label='property type tabs'
                  className={styles.tabsContainer}
                >
                  <Tab
                    label='Residential'
                    id='property-type-tab-0'
                    aria-controls='property-type-tabpanel-0'
                    className={styles.tabItem}
                  />
                  <Tab
                    label='Commercial'
                    id='property-type-tab-1'
                    aria-controls='property-type-tabpanel-1'
                    className={styles.tabItem}
                  />
                </Tabs>
                {propertyTypeTab === 0 && (
                  <div
                    role='tabpanel'
                    hidden={propertyTypeTab !== 0}
                    id='property-type-tabpanel-0'
                    aria-labelledby='property-type-tab-0'
                    className={styles.buttonsContainer}
                  >
                    <div>
                      {[0, 1, 2, 3].map((item) => (
                        <Button
                          key={predefinedVars.propertyKindValues[item].value}
                          className={classNames({
                            [styles.selectButtons]: true,
                            [styles.propertyKindButton]: true,
                            [styles.selectButtonsActive]:
                              filterState?.propertyKind?.[0] ===
                              predefinedVars.propertyKindValues[item].value,
                          })}
                          variant='contained'
                          onClick={() =>
                            handleChangePropertyKind(
                              predefinedVars.propertyKindValues[item].value,
                              'propertyKind',
                              isQueryParams
                            )
                          }
                        >
                          {predefinedVars.propertyKindValues[item].label}
                        </Button>
                      ))}
                    </div>
                    <div>
                      {[4, 5, 6, 7].map((item) => (
                        <Button
                          key={predefinedVars.propertyKindValues[item].value}
                          className={classNames({
                            [styles.selectButtons]: true,
                            [styles.propertyKindButton]: true,
                            [styles.selectButtonsActive]:
                              filterState?.propertyKind?.[0] ===
                              predefinedVars.propertyKindValues[item].value,
                          })}
                          variant='contained'
                          onClick={() =>
                            handleChangePropertyKind(
                              predefinedVars.propertyKindValues[item].value,
                              'propertyKind',
                              isQueryParams
                            )
                          }
                        >
                          {predefinedVars.propertyKindValues[item].label}
                        </Button>
                      ))}
                    </div>
                  </div>
                )}
                {propertyTypeTab === 1 && (
                  <div
                    role='tabpanel'
                    hidden={propertyTypeTab !== 1}
                    id='property-type-tabpanel-1'
                    aria-labelledby='property-type-tab-1'
                    className={styles.buttonsContainer}
                  >
                    <div>
                      {[8, 9, 10].map((item) => (
                        <Button
                          key={predefinedVars.propertyKindValues[item].value}
                          className={classNames({
                            [styles.selectButtons]: true,
                            [styles.propertyKindButton]: true,
                            [styles.selectButtonsActive]:
                              filterState?.propertyKind?.[0] ===
                              predefinedVars.propertyKindValues[item].value,
                          })}
                          variant='contained'
                          onClick={() =>
                            handleChangePropertyKind(
                              predefinedVars.propertyKindValues[item].value,
                              'propertyKind',
                              isQueryParams
                            )
                          }
                        >
                          {predefinedVars.propertyKindValues[item].label}
                        </Button>
                      ))}
                    </div>
                    <div>
                      {[11, 12, 13].map((item) => (
                        <Button
                          key={predefinedVars.propertyKindValues[item].value}
                          className={classNames({
                            [styles.selectButtons]: true,
                            [styles.propertyKindButton]: true,
                            [styles.selectButtonsActive]:
                              filterState?.propertyKind?.[0] ===
                              predefinedVars.propertyKindValues[item].value,
                          })}
                          variant='contained'
                          onClick={() =>
                            handleChangePropertyKind(
                              predefinedVars.propertyKindValues[item].value,
                              'propertyKind',
                              isQueryParams
                            )
                          }
                        >
                          {predefinedVars.propertyKindValues[item].label}
                        </Button>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </FormSelectCustom>
          </Grid>
          <Grid
            item
            xs={12}
            sm={isRent ? 2.3 : 2.8}
            lg={isRent ? 2.3 : 2.8}
            md={isRent ? 2.3 : 2.8}
          >
            <Select
              value={'-1'}
              className={styles.filterSelect}
              IconComponent={({ className }) => (
                <CaretDown
                  className={classNames(styles.filterSelectCaret, {
                    [styles.filterCaretUp]: className.toString().includes('iconOpen'),
                    [styles.filterCaretDown]: !className.toString().includes('iconOpen'),
                  })}
                />
              )}
            >
              <MenuItem style={{ display: 'none' }} hidden key={1} value={'-1'}>
                {bedsBathsText}
              </MenuItem>
              <div className={classNames(styles.selectInnerContainer, styles.filterBeds)}>
                <FormHelperText>
                  <span className={styles.selectInnerTitle}>Beds</span>
                </FormHelperText>
                <div className={styles.selectInnerContent}>
                  {predefinedVars.bedsValues.map(({ label, value }) => (
                    <Button
                      key={value}
                      className={classNames(styles.itemButton, {
                        [styles.active]: filterState?.beds?.includes(value as AdFieldBedsEnum),
                      })}
                      onClick={() =>
                        handleChangeBeds(value as AdFieldBedsEnum, 'beds', isQueryParams)
                      }
                      variant='contained'
                    >
                      {label}
                    </Button>
                  ))}
                </div>
              </div>
            </Select>
          </Grid>
          <Grid
            item
            xs={12}
            sm={isRent ? 2.3 : 2.8}
            lg={isRent ? 2.3 : 2.8}
            md={isRent ? 2.3 : 2.8}
          >
            <Select
              value={'-1'}
              className={styles.filterSelect}
              IconComponent={({ className }) => (
                <CaretDown
                  className={classNames(styles.filterSelectCaret, {
                    [styles.filterCaretUp]: className.toString().includes('iconOpen'),
                    [styles.filterCaretDown]: !className.toString().includes('iconOpen'),
                  })}
                />
              )}
            >
              <MenuItem style={{ display: 'none' }} hidden key={1} value={'-1'}>
                {priceLabel}
              </MenuItem>
              <div className={classNames(styles.selectInnerContainer, styles.filterPrice)}>
                <div className={classNames(styles.selectInnerContent, styles.filterPrice)}>
                  <div>
                    <FormHelperText>
                      <span className={styles.selectInnerLabel}>Minimum</span>
                    </FormHelperText>
                    <NumericFormat
                      customInput={TextField}
                      value={filterState.price.priceMin}
                      allowNegative={false}
                      thousandSeparator=' '
                      decimalScale={0}
                      onKeyDown={(e) => e.stopPropagation()}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        handleChangePrice(e, 'priceMin', isQueryParams)
                      }
                    />
                  </div>
                  <div>
                    <FormHelperText>
                      <span className={styles.selectInnerLabel}>Maximum</span>
                    </FormHelperText>
                    <NumericFormat
                      customInput={TextField}
                      value={filterState.price.priceMax}
                      allowNegative={false}
                      thousandSeparator=' '
                      decimalScale={0}
                      onKeyDown={(e) => e.stopPropagation()}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        handleChangePrice(e, 'priceMax', isQueryParams)
                      }
                      error={Number(priceMin) > Number(priceMax) && Number(priceMax) > 0}
                      helperText={
                        Number(priceMin) > Number(priceMax) && Number(priceMax) > 0
                          ? 'max value should be more or equal than min value'
                          : ''
                      }
                    />
                  </div>
                </div>
              </div>
            </Select>
          </Grid>
          <Grid item xs={12} sm={2.4} lg={2.4} md={2.4}>
            <Stack direction='row' spacing={1}>
              <Box
                sx={{
                  width: {
                    sm: counterFilters > 0 ? '98px' : '166px',
                    xs: counterFilters > 0 ? '50%' : '100%',
                  },
                }}
              >
                <MyButton
                  data={{
                    buttonName: buttonName,
                    styleType: 'filters',
                    variant: 'contained',
                  }}
                  onClick={() => setOpenFilterDialog(true)}
                />
              </Box>
              {counterFilters > 0 && (
                <Box
                  sx={{
                    width: {
                      sm: '60px',
                      xs: '50%',
                    },
                  }}
                >
                  <MyButton
                    data={{
                      buttonName: 'Reset',
                      styleType: 'reset',
                      variant: 'contained',
                    }}
                    onClick={handleResetFilters}
                  />
                </Box>
              )}
            </Stack>
          </Grid>
        </Grid>
        {openFilterDialog && (
          <MoreFiltersDialog
            open={openFilterDialog}
            onClose={handleCloseDialog}
            setOpenDialog={setOpenFilterDialog}
            setInputAddressValue={setInputAddressValue}
            locations={locations}
            inputAddressValue={inputAddressValue}
            handleClear={handleInputAddressClear}
            onSelect={onSelectLocation}
            isRent={isRent}
            filterState={filterState}
            handleChangeTransactionType={handleChangeTransactionType}
            handleChangeRentFrequency={handleChangeRentFrequency}
            handleChangeCompletionStatus={handleChangeCompletionStatus}
            handleChangePropertyKind={handleChangePropertyKind}
            handleChangeBeds={handleChangeBeds}
            handleChangeBaths={handleChangeBaths}
            handleChangeFurnished={handleChangeFurnished}
            handleChangeRenovation={handleChangeRenovation}
            handleChangeFloor={handleChangeFloor}
            handleChangeOccupancy={handleChangeOccupancy}
            handleChangePostHandover={handleChangePostHandover}
            handleChangeHandover={handleChangeHandover}
            handleChangePrice={handleChangePrice}
            handleChangeSize={handleChangeSize}
            handleChangeDownPayment={handleChangeDownPayment}
            handleChangeKeywords={handleChangeKeywords}
            handleChangeCreator={handleChangeCreator}
            handleChangeMyAgency={handleChangeMyAgency}
            handleChangeMyFriend={handleChangeMyFriend}
            handleShowResult={handleShowResult}
            handleResetFilters={handleResetFilters}
            isTariffFree={isTariffFree}
          />
        )}
      </div>
      {/* {isTariffTrial && <TrialBanner />} */}
      {isTariffTrial || isTariffFree && <DiscountBanner hasSubscriptions={hasSubscriptions}/>}
    </>
  );
};
