import { useState, useEffect, SyntheticEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Tab, Tabs, Stack, Container, DialogContent, Link, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { supportProvider } from '@/providers';
import {
  State,
  NotificationTypeEnum,
  UserKindEnum,
  UserTariffPlanEnum,
  TariffPeriod,
} from '@/types';
import { appSetNotification, authAddTariffPrice } from '@/store';
import { MyButton, MyDialog, Loader } from '@/components';
import { TariffBlock } from './TariffBlock';
import { StyleLabel } from '../uiComponents/styled';
import styles from './TariffBlock.module.scss';

interface ITariffPlan {
  name: string;
  price: string;
  interval: string;
  permissions: string[];
  buttonName: string;
  handleClick?: (name: string) => void;
}

export const DialogTariffPlan = ({ open, onClose }: { open: boolean; onClose: () => void }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, tariff } = useSelector(({ auth }: State) => auth);
  const [namePlan, setNamePlan] = useState<string>('');
  const [yearPriceId, setYearPriceId] = useState<string>();
  const [quarterPriceId, setQuarterPriceId] = useState<string>();
  const [monthPriceId, setMonthPriceId] = useState<string>();
  const [yearPriceAmount, setYearPriceAmount] = useState<string>();
  const [quarterPriceAmount, setQuarterPriceAmount] = useState<string>();
  const [monthPriceAmount, setMonthPriceAmount] = useState<string>();
  const [teamsTariffPlan, setTeamsTariffPlan] = useState<string[]>([]);
  const [selectedTariffPlan, setSelectedTariffPlan] = useState<string | undefined>();
  const [openDialogSuccess, setOpenDialogSuccess] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(TariffPeriod.Month);
  const [tariffPlan, setTariffPlan] = useState<ITariffPlan[]>();

  const userKind = user.kind;
  const isBroker = userKind === UserKindEnum.Broker;
  const isManager = userKind === UserKindEnum.Manager;
  const isAgency = userKind === UserKindEnum.Agency;
  const userTariffPlan = user.tariffPlan?.name;
  const isTariffFree = userTariffPlan === UserTariffPlanEnum.Free;
  const isTariffTrial = userTariffPlan === UserTariffPlanEnum.Trial;
  const hasSubscriptions = user.hasSubscriptions || false;

  const isFreeTariff = isTariffFree || isTariffTrial;

  useEffect(() => {
    if (tariff && tariff !== null && isFreeTariff) {
      if (isBroker || isManager) {
        const writeState = () => {
          const tariffPlan = tariff.map((el: any) => {
            const namePlan = el.name;
            const priceYear = el?.prices?.find((price: any) => price.interval === 'year');
            const yearPriceId = priceYear?.id;
            const yearPriceAmount = priceYear?.unitAmount;
            const priceQuarter = el?.prices?.find(
              (price: any) => price.interval === 'month' && price.intervalCount === 3
            );
            const quarterPriceId = priceQuarter?.id;
            const quarterPriceAmount = priceQuarter?.unitAmount;
            const priceMonth = el?.prices?.find(
              (price: any) =>
                price.interval === 'day' ||
                (price.interval === 'month' && price.intervalCount === 1)
            );
            const monthPriceId = priceMonth?.id;
            const monthPriceAmount = priceMonth?.unitAmount;
            setNamePlan(namePlan);
            setYearPriceId(yearPriceId || '');
            setQuarterPriceId(quarterPriceId || '');
            setMonthPriceId(monthPriceId || '');
            setYearPriceAmount(yearPriceAmount || '0');
            setQuarterPriceAmount(quarterPriceAmount || '0');
            setMonthPriceAmount(monthPriceAmount || '0');
          });
          return tariffPlan;
        };
        writeState();
      }
      if (isAgency) {
        const names = tariff?.map((el: any) => el.name);
        const teamsTariffPlan = tariff?.map((el: any) => el.description);
        const prices = tariff?.map((el: any) => el.prices);
        const priceYear = prices[0]?.find((price: any) => price.interval === 'year');
        const priceQuarter = prices[0]?.find(
          (price: any) => price.interval === 'month' && price.intervalCount === 1
        );
        const priceMonth = prices[0]?.find(
          (price: any) =>
            price.interval === 'day' || (price.interval === 'month' && price.intervalCount === 1)
        );
        const yearPriceId = priceYear?.id;
        const yearPriceAmount = priceYear?.unitAmount;
        const quarterPriceId = priceQuarter?.id;
        const quarterPriceAmount = priceQuarter?.unitAmount;
        const monthPriceId = priceMonth?.id;
        const monthPriceAmount = priceMonth?.unitAmount;
        setNamePlan(names[0]);
        setTeamsTariffPlan(teamsTariffPlan);
        setYearPriceId(yearPriceId || '');
        setQuarterPriceId(quarterPriceId || '');
        setMonthPriceId(monthPriceId || '');
        setYearPriceAmount(yearPriceAmount || '0');
        setQuarterPriceAmount(quarterPriceAmount || '0');
        setMonthPriceAmount(monthPriceAmount || '0');
        setSelectedTariffPlan(teamsTariffPlan[0]);
      }
    }
  }, [tariff]);

  useEffect(() => {
    if (tariffPlanMonth && tariffPlanQuarter && tariffPlanYear) {
      if (activeTab === TariffPeriod.Annual) {
        setTariffPlan(tariffPlanYear);
      } else if (activeTab === TariffPeriod.Quarter) {
        setTariffPlan(tariffPlanQuarter);
      } else {
        setTariffPlan(tariffPlanMonth);
      }
    }
  }, [namePlan]);

  useEffect(() => {
    if (selectedTariffPlan) {
      const selectedPlan = tariff.find((el: any) => el.description === selectedTariffPlan);
      const namePlan = selectedPlan.name;
      const priceYear = selectedPlan?.prices?.find((price: any) => price.interval === 'year');
      const priceQuarter = selectedPlan?.prices?.find(
        (price: any) => price.interval === 'month' && price.intervalCount === 3
      );
      const priceMonth = selectedPlan?.prices?.find(
        (price: any) =>
          price.interval === 'day' || (price.interval === 'month' && price.intervalCount === 1)
      );
      const yearPriceId = priceYear?.id;
      const yearPriceAmount = priceYear?.unitAmount;
      const quarterPriceId = priceQuarter?.id;
      const quarterPriceAmount = priceQuarter?.unitAmount;
      const monthPriceId = priceMonth?.id;
      const monthPriceAmount = priceMonth?.unitAmount;
      setNamePlan(namePlan);
      setYearPriceId(yearPriceId || '');
      setQuarterPriceId(quarterPriceId || '');
      setMonthPriceId(monthPriceId || '');
      setYearPriceAmount(yearPriceAmount || '0');
      setQuarterPriceAmount(quarterPriceAmount || '0');
      setMonthPriceAmount(monthPriceAmount || '0');
    }
  }, [selectedTariffPlan]);

  const permissionsFreeTariffPlan = [
    'Add listing',
    'Updating listing',
    'Use favorites',
    'Use messaging',
    'Use filters',
  ];

  const permissions = [
    'Add listing',
    'Add client request',
    'View contact details',
    'Search by seller',
    'Updating listing',
    'Updating client request',
    'Save PDF',
    'Write comments',
    'View profiles of users',
    'Creates groups in messaging',
    'Notification of new client request',
    'View transactions of DLD',
    'Use filters',
    'View all photos',
    'View notification',
    'Use favorites',
    'Use messaging',
  ];

  const handleClick = (name: string) => {
    if (name === 'year') {
      dispatch(authAddTariffPrice(yearPriceId));
    } else if (name === 'quarter') {
      dispatch(authAddTariffPrice(quarterPriceId));
    } else {
      dispatch(authAddTariffPrice(monthPriceId));
    }
    handleClose();
    navigate('/payment');
  };

  const freeTariffPlan: ITariffPlan = {
    name: 'Free',
    price: '0',
    interval: 'year/quarter/month',
    permissions: isTariffFree ? permissionsFreeTariffPlan : permissions,
    buttonName: 'Current',
    handleClick: undefined,
  };

  const tariffPlanMonth: ITariffPlan[] = [
    freeTariffPlan,
    {
      name: namePlan,
      price: monthPriceAmount || '0',
      interval: 'month',
      permissions: permissions,
      handleClick: handleClick,
      buttonName: hasSubscriptions ? 'Upgrade' : 'Get trial',
    },
  ];

  const tariffPlanYear: ITariffPlan[] = [
    freeTariffPlan,
    {
      name: namePlan,
      price: yearPriceAmount || '0',
      interval: 'year',
      permissions: permissions,
      handleClick: handleClick,
      buttonName: hasSubscriptions ? 'Upgrade' : 'Get trial',
    },
  ];

  const tariffPlanQuarter: ITariffPlan[] = [
    freeTariffPlan,
    {
      name: namePlan,
      price: quarterPriceAmount || '0',
      interval: 'quarter',
      permissions: permissions,
      handleClick: handleClick,
      buttonName: hasSubscriptions ? 'Upgrade' : 'Get trial',
    },
  ];

  const handleClose = () => {
    setSelectedTariffPlan(teamsTariffPlan[0]);
    setActiveTab(TariffPeriod.Month);
    onClose();
  };

  const handleConfirmTariff = async () => {
    const dataSubmit = {
      name: user.name || '',
      email: user.email || undefined,
      phone: user.phone || undefined,
      text: 'The user, contacts above, left a request to organize a meeting with his agency, so that the latter would pay the subscription! Contact and arrange a meeting!',
    };
    if (dataSubmit) {
      setIsLoading(true);
      const { ok, message, status } = await supportProvider.support(dataSubmit);
      if (ok) {
        setOpenDialogSuccess(true);
      } else {
        dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
      }
      setIsLoading(false);
    }
  };

  const handleChangeTab = (_: SyntheticEvent, newStatus: TariffPeriod) => {
    setActiveTab(newStatus);
    if (newStatus === TariffPeriod.Month) {
      setTariffPlan(tariffPlanMonth);
    } else if (newStatus === TariffPeriod.Annual) {
      setTariffPlan(tariffPlanYear);
    } else if (newStatus === TariffPeriod.Quarter) {
      setTariffPlan(tariffPlanQuarter);
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <MyDialog open={open} onClose={handleClose} dialogTitle='Unlock all features' width='100%'>
        <Container component='main'>
          {!hasSubscriptions && (
            <Stack justifyContent='center'>
              <Link
                sx={{
                  textAlign: 'center',
                  fontWeight: '800',
                  color: '#1650FF',
                  textDecoration: 'none',
                  whiteSpace: 'normal',
                }}
              >
                <Typography>
                  Get free trial period for 3 days by choosing a tariff and linking a card!
                </Typography>
                <Typography>You can unlink a card anytime!</Typography>
              </Link>
            </Stack>
          )}
          <DialogContent
            sx={{
              maxHeight: '100%',
              overflowY: 'auto',
              textAlign: 'center',
              margin: '0',
              alignContent: 'center',
              justifyContent: 'center',
            }}
            data-qa={'dialogTariffPlanContent'}
          >
            <Stack justifyContent='center' alignContent='center'>
              <Box className={styles.tabsContainer} position='relative'>
                <Stack
                  justifyContent='center'
                  alignItems='center'
                  sx={{
                    width: '40px',
                    height: '20px',
                    backgroundColor: '#1650FF',
                    color: 'white',
                    fontSize: '12px',
                    borderRadius: '5px',
                    transform: 'rotate(12deg)',
                    position: 'absolute',
                    top: '-10px',
                    left: 'calc(31%)',
                  }}
                >
                  -44%
                </Stack>

                {/* -10% для Quarter */}
                <Stack
                  justifyContent='center'
                  alignItems='center'
                  sx={{
                    width: '40px',
                    height: '20px',
                    backgroundColor: '#1650FF',
                    color: 'white',
                    fontSize: '12px',
                    borderRadius: '5px',
                    transform: 'rotate(12deg)',
                    position: 'absolute',
                    top: '-10px',
                    left: 'calc(50% + 20px)',
                  }}
                >
                  -15%
                </Stack>
                <Tabs value={activeTab} onChange={handleChangeTab} aria-label='view tabs'>
                  <Tab
                    id='tab-annual'
                    aria-controls='tabpanel-annual'
                    label='Annual'
                    value={TariffPeriod.Annual}
                    className={styles.tabItem}
                  />
                  <Tab
                    id='tab-quarter'
                    aria-controls='tabpanel-quarter'
                    label='Quarter'
                    value={TariffPeriod.Quarter}
                    className={styles.tabItem}
                  />
                  <Tab
                    id='tab-month'
                    aria-controls='tabpanel-month'
                    label='Month'
                    value={TariffPeriod.Month}
                    className={styles.tabItem}
                  />
                </Tabs>
              </Box>

              <Stack
                spacing={4}
                justifyContent='space-between'
                direction={{ xs: 'column', sm: 'row' }}
              >
                {tariffPlan &&
                  tariffPlan.map((tariff) => {
                    return (
                      <TariffBlock
                        key={tariff.interval}
                        name={tariff.name}
                        price={tariff.price}
                        interval={tariff.interval}
                        permissions={tariff.permissions}
                        handleClick={tariff.handleClick}
                        buttonName={tariff.buttonName}
                        selectedTariffPlan={selectedTariffPlan}
                        setSelectedTariffPlan={setSelectedTariffPlan}
                        teamsTariffPlan={teamsTariffPlan}
                        isAgency={isAgency}
                      />
                    );
                  })}
              </Stack>
            </Stack>
          </DialogContent>
          {!isAgency && (
            <Stack justifyContent='center'>
              <Link
                onClick={handleConfirmTariff}
                sx={{
                  textAlign: 'center',
                  color: '#2A3842',
                  textDecoration: 'none',
                  whiteSpace: 'normal',
                  cursor: 'pointer',
                  '&:hover': {
                    cursor: 'pointer',
                    textDecoration: 'underline',
                  },
                }}
              >
                <Typography>Let your agency take care of it! Schedule a meeting now.</Typography>
              </Link>
            </Stack>
          )}
        </Container>
      </MyDialog>
      <MyDialog
        open={openDialogSuccess}
        onClose={() => setOpenDialogSuccess(false)}
        dialogTitle='Last step'
        width='650'
      >
        <Stack spacing={2} sx={{ width: '100%' }}>
          <Stack direction='row' justifyContent='center' display='flex'>
            <img src='/Img/Pictures/operatorSuccess.svg' style={{ width: '230px' }} />
          </Stack>
          <StyleLabel style={{ textAlign: 'center' }}>
            Our support manager will contact your agency to pay for your subscription!
          </StyleLabel>

          <MyButton
            data={{
              buttonName: 'Okay',
              variant: 'contained',
              customWidth: '358px',
              styleType: 'dialog',
            }}
            onClick={() => setOpenDialogSuccess(false)}
          />
        </Stack>
      </MyDialog>
    </>
  );
};
