import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import { appSetNotification, authGetMyUser } from '@/store';
import { State, NotificationTypeEnum, UserModel } from '@/types';
import { usersProvider } from '@/providers';
import { Box, Stack, Typography } from '@mui/material';
import { MyButton, Loader } from '@/components';

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#32325d',
      fontFamily: 'Arial, sans-serif',
      fontSmoothing: 'antialiased',
      border: '1px solid #e9eef1',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
};

export const SubscriptionForm = () => {
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [message, setMessage] = useState<string | null | undefined>(null);
  const { user, tariffPriceId } = useSelector(({ auth }: State) => auth);
  const dispatch = useDispatch();

  const delay = (ms: number): Promise<void> => new Promise((resolve) => setTimeout(resolve, ms));

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardNumberElement = elements.getElement(CardNumberElement);
    const cardExpiryElement = elements.getElement(CardExpiryElement);
    const cardCvcElement = elements.getElement(CardCvcElement);

    if (!cardNumberElement || !cardExpiryElement || !cardCvcElement) {
      setMessage('Card information is missing');
      return;
    }
    setIsProcessing(true);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardNumberElement,
    });

    if (error) {
      setMessage(error.message || 'Failed to create payment method');
      return;
    }

    if (!paymentMethod) {
      setMessage('Payment method is not available');
      return;
    }

    const { ok, data, status, message } = await usersProvider.createSubscription({
      customerId: user.id,
      paymentMethodId: paymentMethod.id,
      priceId: tariffPriceId,
    });
    if (!ok) {
      dispatch(appSetNotification(NotificationTypeEnum.Error, message, status));
      setMessage(message);
    } else {
      await delay(1000);
      const { ok: okUser, data: dataUser } = await usersProvider.getMyUser();
      if (okUser && dataUser) {
        dispatch(authGetMyUser(dataUser as UserModel));
      }
      navigate('/payment-ok');
    }
    setIsProcessing(false);
  };

  return (
    <section style={{ margin: '0 auto' }}>
      <Typography
        sx={{ fontSize: '20px', fontWeight: 600, marginBottom: '15px', textAlign: 'center' }}
      >
        Create Payment Method
      </Typography>
      <form id='payment-form' onSubmit={handleSubmit}>
        <Stack
          spacing={5}
          sx={{
            width: '100%',
            backgroundColor: 'white',
            borderRadius: '14px',
            padding: '20px',
            border: '1px solid #E9EEF1',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
          }}
        >
          <Stack spacing={3}>
            <Box>
              <Typography sx={{ marginBottom: '8px' }}>Card Number</Typography>
              <Box sx={{ border: '1px solid #E9EEF1', borderRadius: '4px', padding: '10px' }}>
                <CardNumberElement options={CARD_ELEMENT_OPTIONS} />
              </Box>
            </Box>

            <Box>
              <Typography sx={{ marginBottom: '8px' }}>Expiration Date</Typography>
              <Box sx={{ border: '1px solid #E9EEF1', borderRadius: '4px', padding: '10px' }}>
                <CardExpiryElement options={CARD_ELEMENT_OPTIONS} />
              </Box>
            </Box>

            <Box>
              <Typography sx={{ marginBottom: '8px' }}>Security Code (CVC)</Typography>
              <Box sx={{ border: '1px solid #E9EEF1', borderRadius: '4px', padding: '10px' }}>
                <CardCvcElement options={CARD_ELEMENT_OPTIONS} />
              </Box>
            </Box>
          </Stack>
          <Stack spacing={2}>
            <MyButton
              disabled={isProcessing || !stripe}
              data={{
                buttonName: isProcessing ? 'Processing...' : 'Create',
                customWidth: '300px',
                variant: 'contained',
                buttonType: 'submit',
                styleType: 'edit',
              }}
            />
            {message && (
              <Typography sx={{ color: 'red', textAlign: 'center' }}>{message}</Typography>
            )}
          </Stack>
        </Stack>
      </form>
    </section>
  );
};
