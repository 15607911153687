import React from 'react';
import { UpgradeButton, AddListingButton, ClientRequestButton } from '@/components/Header/Header';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  List,
  ListItemAvatar,
  ListItemText,
  Box,
  Stack,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { State, UserKindEnum, HeaderDialogsEnum } from '@/types';
import { OpListItem } from '@/components/Sidebar/ListItem';
import { useDialogs } from '@/components/Dashboard/DialogsContext';
import { SidebarMenu } from '@/components';
import { capitalizeFirstLetter, stringAvatar } from '@/utils';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  EditProfileIcon,
  SettingsIcon,
  ViewProfileIcon,
  ExitIcon,
} from '@/components/Sidebar/icons';
import { authProvider } from '@/providers';
import { authLogout } from '@/store';
import { useNavigate } from 'react-router-dom';

export function HeaderMenu({
  onClick,
  isVisible,
  isAdmin,
  isClientRequestVisible,
  isUpgradeButtonVisible,
  isTariffFree,
}: {
  onClick?: () => void;
  isVisible?: boolean;
  isAdmin?: boolean;
  isClientRequestVisible?: boolean;
  isUpgradeButtonVisible?: boolean;
  isTariffFree: boolean;
}) {
  const { auth: authState } = useSelector((state: State) => state);
  const user = authState.user;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { openDialog, setOpenDialog } = useDialogs();

  const hasSubscriptions = user.hasSubscriptions || false;
  const isTrial = user.statusSubscription === 'trialing';

  const calculateDaysRemaining = (trialEndDate: string): number => {
    const currentDate = new Date();
    const trialEnd = new Date(trialEndDate);
    const timeDifference = trialEnd.getTime() - currentDate.getTime();
    return Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
  };
  const trialPeriod = user?.trialEnd && calculateDaysRemaining(user?.trialEnd);

  const periodValue = isTariffFree 
    ? hasSubscriptions 
      ? 'Upgrade'
      : 'Get trial' 
    : 
      isTrial && trialPeriod
      ? `Trial: ${trialPeriod} days left`
      : 'Active';

  const handleClickOpenDialog = (dialogType: HeaderDialogsEnum) => {
    setOpenDialog((prevState) => ({
      ...prevState,
      [dialogType]: !prevState[dialogType],
    }));
  };

  return (
    <Box
      sx={{
        paddingBottom: '10px',
        maxHeight: 'calc(100vh - 72px)',
        minHeight: {
          xs: 'calc(100vh - 72px)',
          sm: 'auto',
        },
        overflowY: 'auto',
      }}
    >
      <Accordion disableGutters elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{
            padding: '0',
          }}
        >
          <ListItemAvatar
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Avatar src={user.media?.[0]?.fileUrl} {...stringAvatar(user.name)} />
          </ListItemAvatar>
          {/* <ListItemText primary={user.name} secondary={capitalizeFirstLetter(user.kind)} /> */}
          <Box sx={{ marginLeft: '5px', color: '#2a3842' }}>
            <Typography sx={{ fontSize: '14px', fontWeight: 600, textAlign: 'left' }}>{user.tariffPlan?.name}</Typography>
            <Typography sx={{ fontSize: '14px', textAlign: 'left', opacity: 0.5 }}>
              {periodValue}
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails
          style={{
            padding: 0,
          }}
        >
          <List
            onClick={() => {
              onClick && onClick();
            }}
            sx={{
              '& .MuiListItem-root': {
                borderTop: '1px solid #E8E8E8',
                '& .MuiListItemButton-root.Mui-selected': {
                  color: '#1650FF',
                  backgroundColor: 'transparent',
                },
              },
            }}
          >
            <OpListItem
              href='/profile/view'
              variant='mobile'
              icon={<ViewProfileIcon />}
              content={<span>View Profile</span>}
            />
            <OpListItem
              href='/profile/edit'
              variant='mobile'
              icon={<EditProfileIcon />}
              content={<span>Edit Profile</span>}
            />
            <OpListItem
              href='/profile/settings'
              variant='mobile'
              icon={<SettingsIcon />}
              content={<span>Settings</span>}
            />
            <OpListItem
              variant='mobile'
              icon={<ExitIcon />}
              content={<span>Exit</span>}
              onClick={() => {
                authProvider.logout();
                dispatch(authLogout());
                navigate('/');
              }}
            />
          </List>
        </AccordionDetails>
      </Accordion>

      <Stack spacing={1}>
        <UpgradeButton
          style={{ height: '48px' }} 
          isVisible={isUpgradeButtonVisible}
          isAdmin={isAdmin}
          handleClickOpenDialog={handleClickOpenDialog}
          hasSubscriptions={hasSubscriptions}
        />
        <AddListingButton 
          style={{ height: '48px' }} 
          isVisible={isVisible} 
          isAdmin={isAdmin} 
          handleClickOpenDialog={handleClickOpenDialog}
         />
        <ClientRequestButton
          style={{ height: '48px' }}
          isVisible={isVisible}
          isAdmin={isAdmin}
          isClientRequestVisible={isClientRequestVisible}
          handleClickOpenDialog={handleClickOpenDialog}
          isTariffFree={isTariffFree}
        />
      </Stack>

      <SidebarMenu
        variant='mobile'
        onClick={() => {
          onClick && onClick();
        }}
        sx={{
          '& .MuiListItem-root': {
            borderBottom: '1px solid #E8E8E8',
            '& .MuiListItemButton-root.Mui-selected': {
              color: '#1650FF',
              backgroundColor: 'transparent',
            },
          },
        }}
      />
    </Box>
  );
}
