import { Route, Routes } from 'react-router-dom';

import {
  AdFormPage,
  AdPage,
  AdminNewBuildingsPage,
  AgencyLicensePage,
  BrokerLicensePage,
  CommentsReportPage,
  DeveloperLicensePage,
  EditProfilePage,
  HomePage,
  InviteLinkPage,
  LocalizationsListPage,
  MessengerPage,
  MyAdsPage,
  MyTeamPage,
  NotFoundPage,
  OffplanAdminPage,
  OffPlanViewPage,
  AdsPdfManagerPage,
  UnitsPdfManagerPage,
  OffplanPdfManagerPage,
  RequestsByMePage,
  RequestsForMePage,
  RequestsPage,
  UnitsAdminPage,
  UnitsViewPage,
  UserListPage,
  UserSettings,
  UsersProfilePage,
  ViewProfilePage,
  XmlUploadPage,
  CreatePlanPage,
  PlanViewPage,
  OffPlanPage,
  ClientRequestFormPage,
  PropertyRequestsPage,
  SignUpPage,
  LogInPage,
  MetricsPage,
  LocationListPage,
  RentPage,
  PaymentPage,
  PaymentOkPage,
  PaymentInformationPage,
  FriendsPage,
  ReferralsPage,
  ReferralLinkPage,
} from '@/components';
import {FavoritesPage} from "@/components/Dashboard/FavoritesPage";


export const Router = () => (
  <Routes>
    <Route path='/payment' element={<PaymentPage />} />
    <Route path='/payment-ok' element={<PaymentOkPage />} />
    <Route path='/signUp' element={<SignUpPage />} />
    <Route path='/logIn' element={<LogInPage />} />
    <Route path='/chat' element={<MessengerPage />} />
    <Route path='/chat/:uid' element={<MessengerPage />} />
    <Route path='/chat/:uid/:message' element={<MessengerPage />} />
    <Route path='/profile/view' element={<ViewProfilePage />} />
    <Route path='/profile/edit' element={<EditProfilePage />} />
    <Route path='/profile/settings' element={<UserSettings />} />
    <Route path='/me/unsubscribe/:type' element={<ViewProfilePage />} />
    <Route path='/users/:id' element={<UsersProfilePage />} />
    <Route path='/ads/edit/:id' element={<AdFormPage />} />
    <Route path='/ads/create-pdf/:id' element={<AdsPdfManagerPage />} />
    <Route path='/ads/create' element={<AdFormPage />} />
    <Route path='/ads/my' element={<MyAdsPage />} />
    <Route path='/ads/rent' element={<RentPage />} />
    <Route path='/ads/:id' element={<AdPage />} />
    <Route path='/client-requests' element={<PropertyRequestsPage />} />
    <Route path='/client-request/create' element={<ClientRequestFormPage />} />
    <Route path='/client-request/edit/:id' element={<ClientRequestFormPage />} />
    <Route path='/notifications' element={<RequestsPage />} />
    <Route path='/notifications/forme/:id' element={<RequestsForMePage />} />
    <Route path='/notifications/byme/:id' element={<RequestsByMePage />} />
    <Route path='/myteam' element={<MyTeamPage />} />
    <Route path='/favorites' element={<FavoritesPage />} />
    <Route path='/friends' element={<FriendsPage />} />
    <Route path='/referrals' element={<ReferralsPage />} />
    <Route path='/link' element={<ReferralLinkPage />} />
    <Route path='/invitelink/:code' element={<InviteLinkPage />} />
    <Route path='/admin/users' element={<UserListPage />} />
    <Route path='/admin/agency/licenses' element={<AgencyLicensePage />} />
    <Route path='/admin/broker/licenses' element={<BrokerLicensePage />} />
    <Route path='/admin/developer/licenses' element={<DeveloperLicensePage />} />
    <Route path='/admin/localizations' element={<LocalizationsListPage />} />
    <Route path='/admin/comments-report' element={<CommentsReportPage />} />
    <Route path='/admin/offplans' element={<AdminNewBuildingsPage />} />
    <Route path='/offplans' element={<OffPlanPage />} />
    <Route path='/admin/offplan/create' element={<OffplanAdminPage />} />
    <Route path='/admin/units' element={<UnitsViewPage />} />
    <Route path='/admin/units/create/:id' element={<UnitsAdminPage />} />
    <Route path='/admin/units/create-pdf/:id' element={<UnitsPdfManagerPage />} />
    <Route path='/admin/plan/create/:id' element={<CreatePlanPage />} />
    <Route path='/admin/plan/:planId/edit/:id' element={<CreatePlanPage />} />
    <Route path='/admin/offplan/:id' element={<OffplanAdminPage />} />
    <Route path='/admin/metrics' element={<MetricsPage />} />
    <Route path='/admin/payment-information' element={<PaymentInformationPage />} />
    <Route path='/admin/locationList' element={<LocationListPage />} />
    <Route path='/offplan/:id' element={<OffPlanViewPage />} />
    <Route path='/offplan/create-pdf/:id' element={<OffplanPdfManagerPage />} />
    <Route path='/offplan/:id/plan/:planId' element={<PlanViewPage />} />
    <Route path='/xml-upload' element={<XmlUploadPage />} />
    <Route path='/:any' element={<NotFoundPage />} />
    <Route path='/' element={<HomePage />} />
  </Routes>
);
