import { Grid, Typography } from '@mui/material';
import { HeaderDialogsEnum } from '@/types';
import { MyButton } from '@/components';
import styles from '../AdsFilters.module.scss';
import { useDialogs } from '@/components/Dashboard/DialogsContext';

export const DiscountBanner = ({hasSubscriptions}: {hasSubscriptions: boolean}) => {
  const { openDialog, setOpenDialog } = useDialogs();
  const handleClickOpenDialog = (dialogType: HeaderDialogsEnum) => {
    setOpenDialog((prevState) => ({
      ...prevState,
      [dialogType]: !prevState[dialogType],
    }));
  };

  return (
    <div className={styles.discountBannerContainer}>
      <Grid container alignItems='center'>
        <Grid item xs={12} sm={8}>
          <Typography
            sx={{
              fontSize: { sm: '40px', xs: '30px' },
              color: '#1650FF',
              fontWeight: 800,
              textAlign: { sm: 'left', xs: 'center' },
            }}
          >
            Don't lose chance
          </Typography>
          <Typography
            sx={{
              fontSize: { sm: '40px', xs: '30px' },
              color: '#1650FF',
              fontWeight: 800,
              textAlign: { sm: 'left', xs: 'center' },
            }}
          >
            to have full access
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4} container alignItems='flex-end' flexDirection={'column'}>
          <Grid item xs={9} sm={10} spacing={.5} container alignItems='center'>
            <Grid item xs={8} sm={12}>
              <Typography
                sx={{
                  fontSize: '20px',
                  color: '#2A384250',
                  textAlign: { sm: 'right', xs: 'center' },
                  textDecoration: 'line-through',
                  textDecorationColor: 'red',
                }}
              >
                pay 179
              </Typography>
            </Grid>
            <Grid item xs={8} sm={12}>
              <Typography
                sx={{
                  fontSize: { sm: '32px', xs: '30px' },
                  color: '#1650FF',
                  textAlign: { sm: 'right', xs: 'center' },
                  fontWeight: 800,
                }}
              >
                99 AED
                <span style={{ color: '#2A3842' }}>{` NOW`}</span>
              </Typography>
            </Grid>
            <Grid item xs={8} sm={12} sx={{ justifyContent: { sm: 'flex-end', xs: 'center' } }}>
              <MyButton
                data={{
                  buttonName: hasSubscriptions ? 'Upgrade' : 'Get trial',
                  variant: 'contained',
                  customWidth: '166px',
                  styleType: 'discount'
                }}
                onClick={() => handleClickOpenDialog(HeaderDialogsEnum.Tariff)}
              />
            </Grid>
            <Grid item xs={8} sm={12}>
              <Typography
                sx={{
                  fontSize: '10px',
                  fontWeight: 800,
                  color: '#1650FF',
                  textAlign: { sm: 'right', xs: 'center' },
                }}
              >
                * valid till 1 January
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
