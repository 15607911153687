import { useState, useEffect } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { SubscriptionForm } from './SubscriptionForm ';
import { Loader } from '@/components';

export const Payment = () => {
  const [stripe, setStripe] = useState<Stripe | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchStripe = async () => {
      setIsLoading(true);
      const stripeInstance = await loadStripe(
        process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY ||
          'pk_test_51PNtZzP0ssolsyviXqbAQLiZpb0rgYpg0f6Y8sw7VznLR7oBQVoSTnGlqXWuKaG6HFqS1OCDhbCCnafZ0SO3ufIM000mrd1B44'
      );
      setIsLoading(false);
      setStripe(stripeInstance);
    };
    fetchStripe();
  }, []);

  if (isLoading || !stripe) {
    return <Loader />;
  }

  return (
    <Elements stripe={stripe}>
      <SubscriptionForm />
    </Elements>
  );
};
