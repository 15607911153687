import { Stack, Typography, FormControl, MenuItem } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { MyButton } from '@/components';
import { ReactComponent as CaretDown } from './CaretDown.svg';
import styles from './TariffBlock.module.scss';

export const TariffBlock = ({
  name,
  price,
  interval,
  permissions,
  handleClick,
  buttonName,
  selectedTariffPlan,
  setSelectedTariffPlan,
  teamsTariffPlan,
  isAgency,
}: {
  name: string;
  price: string;
  interval: string;
  permissions: string[];
  handleClick?: (name: string) => void;
  buttonName: string;
  selectedTariffPlan?: string;
  setSelectedTariffPlan?: (value: string) => void;
  teamsTariffPlan?: string[];
  isAgency: boolean;
}) => {
  const modifiedStr = (price: string) => {
    const str = price.toString();
    if (str?.length > 2) {
      return str.slice(0, -2);
    } else {
      return '0';
    }
  };
  const isDisable = name === 'Free' || name === 'Trial';

  const oldPriceGenerate = () => {
    let oldPrice = '';
    if (name === 'Free' || name === 'Trial') return oldPrice;
    if (interval === 'year') {
      if (name === 'Pro') {
        oldPrice = '1199 AED ';
      } else if (name === 'Team (5 users)') {
        oldPrice = '5107 AED ';
      } else if (name === 'Team (20 users)') {
        oldPrice = '18043 AED ';
      } else if (name === 'Team (50 users)') {
        oldPrice = '26880 AED ';
      }
    } else if (interval === 'quarter') {
      if (name === 'Pro') {
        oldPrice = '456 AED ';
      } else if (name === 'Team (5 users)') {
        oldPrice = '1938 AED ';
      } else if (name === 'Team (20 users)') {
        oldPrice = '6847 AED ';
      } else if (name === 'Team (50 users)') {
        oldPrice = '10200 AED ';
      }
    } else {
      if (name === 'Pro') {
        oldPrice = '179 AED ';
      } else if (name === 'Team (5 users)') {
        oldPrice = '760 AED ';
      } else if (name === 'Team (20 users)') {
        oldPrice = '2685 AED ';
      } else if (name === 'Team (50 users)') {
        oldPrice = '4000 AED ';
      }
    }
    return oldPrice;
  };

  return (
    <Stack sx={{ maxHeight: '100%', overflowY: 'auto', textAlign: 'center' }}>
      <Stack
        direction='column'
        alignItems='center'
        justifyContent='flex-start'
        spacing={1}
        sx={{
          width: { xs: '260px', sm: '300px' },
          minHeight: isAgency ? '610px' : '560px',
          marginBottom: '20px',
          backgroundColor: '#F9FCFF',
          padding: '15px',
          border: '1px solid #E9EEF1',
          borderRadius: '8px',
        }}
      >
        <Stack direction='row'>
          <Typography sx={{ fontSize: '20px', fontWeight: 600 }}>{name}</Typography>
        </Stack>
        <Stack>
          <Typography sx={{ fontSize: '24px', fontWeight: 600, color: '#1650FF' }}>
            <span
              style={{
                fontSize: '18px',
                opacity: 0.5,
                textDecoration: 'line-through',
                textDecorationColor: 'red',
              }}
            >
              {oldPriceGenerate()}{' '}
            </span>
            {modifiedStr(price)} AED
          </Typography>
          <Typography sx={{ fontSize: '14px', color: '#2A3842', opacity: 0.5 }}>
            per {interval}
          </Typography>
        </Stack>
        {selectedTariffPlan && (
          <Stack>
            <FormControl>
              <Select
                sx={{
                  borderRadius: '14px',
                  border: `1px solid '#E9EEF1'`,
                  minWidth: '200px',
                  textAlign: 'left',
                }}
                IconComponent={(_props) => {
                  const rotate = _props.className.toString().includes('iconOpen');
                  return (
                    <div
                      className={styles.caretDown}
                      style={{
                        transform: rotate ? 'rotate(180deg)' : 'none',
                      }}
                    >
                      <CaretDown />
                    </div>
                  );
                }}
                value={selectedTariffPlan}
                onChange={(e: SelectChangeEvent) =>
                  setSelectedTariffPlan?.(e.target.value as string)
                }
              >
                {teamsTariffPlan?.map((tariff: string, index: number) => (
                  <MenuItem
                    sx={{
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    key={index}
                    value={tariff}
                  >
                    {tariff}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        )}
        <Stack spacing={0.5}>
          {permissions.map((permission) => (
            <Typography key={permission} sx={{ fontSize: '14px' }}>
              {permission}
            </Typography>
          ))}
        </Stack>
      </Stack>
      <MyButton
        disabled={isDisable}
        data={{
          buttonName: buttonName,
          customWidth: '300px',
          variant: 'contained',
          buttonType: 'button',
          styleType: 'edit',
        }}
        onClick={() => handleClick?.(interval)}
      />
    </Stack>
  );
};
