import { Stack, Typography } from '@mui/material';

export const PaymentOk = () => {
  return (
    <Stack>
      <Stack spacing={1}>
        <Typography variant='h4'>
          Payment Method was
          <span style={{ color: '#1650FF' }}> created!</span>
        </Typography>
        <Typography sx={{ fontSize: 16 }}>
          Your card has been successfully linked, and you have activated a 3-day trial period!
        </Typography>
      </Stack>
    </Stack>
  );
};
